var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "flex-grow-1" },
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-row",
            {
              staticClass: "mt-5 mb-5 justify-content-between",
              attrs: { "align-v": "center" },
            },
            [
              _c("b-col", { attrs: { cols: "auto" } }, [
                _vm.currentDoctor.name
                  ? _c(
                      "h1",
                      {
                        staticClass: "mb-0 text-primary",
                        staticStyle: { "font-size": "22px" },
                      },
                      [
                        _vm._v(
                          " Replies for " + _vm._s(_vm.currentDoctor.name) + " "
                        ),
                      ]
                    )
                  : _c(
                      "h1",
                      {
                        staticClass: "mb-0 text-primary",
                        staticStyle: { "font-size": "22px" },
                      },
                      [
                        _vm._v(
                          " Replies for Dr. " + _vm._s(_vm.doctorName) + " "
                        ),
                      ]
                    ),
              ]),
              !_vm.currentDoctor.id
                ? _c(
                    "b-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "px-5",
                          attrs: { pill: "", variant: "primary" },
                          on: { click: _vm.createNewTemplate },
                        },
                        [_vm._v("New Template")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-input-group-append", [
                        _c("span", { staticClass: "input-group-text" }, [
                          _c("i", { staticClass: "fe fe-search" }),
                        ]),
                      ]),
                      _c("b-form-input", {
                        staticClass: "form-control list-search",
                        attrs: { placeholder: "Search" },
                        on: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return _vm.searchReplies.apply(null, arguments)
                          },
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "flex-grow-1 mt-4" },
            [
              _c(
                "b-col",
                { attrs: { cols: "3" } },
                [
                  _c("span", { staticClass: "text-muted" }, [_vm._v("Reply")]),
                  _c("b-form-select", {
                    attrs: { options: _vm.categoryOptions },
                    on: { input: _vm.getCategory },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function () {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: "" } },
                              [_vm._v(" All Replies ")]
                            ),
                            _c(
                              "b-form-select-option",
                              { attrs: { value: "default" } },
                              [_vm._v(" Defaults ")]
                            ),
                            _c(
                              "b-form-select-option",
                              { attrs: { disabled: "" } },
                              [_vm._v("----")]
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                    model: {
                      value: _vm.selectedCategory,
                      callback: function ($$v) {
                        _vm.selectedCategory = $$v
                      },
                      expression: "selectedCategory",
                    },
                  }),
                  _c(
                    "b-list-group",
                    {
                      ref: "canned-reply-modal-list",
                      staticClass: "replyList",
                    },
                    [
                      _vm._l(_vm.defaultReplies, function (item) {
                        return _c(
                          "b-list-group-item",
                          {
                            key: item.id,
                            attrs: { active: item.id == _vm.activeItemID },
                            on: {
                              click: function ($event) {
                                return _vm.setActiveItem(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                      _vm.savedReplies.length == 0
                        ? _c("b-list-group-item", { attrs: { disabled: "" } }, [
                            _vm._v(
                              " There are no replies under this category. "
                            ),
                          ])
                        : _vm._e(),
                      _vm._l(_vm.savedReplies, function (item) {
                        return _c(
                          "b-list-group-item",
                          {
                            key: item.id,
                            attrs: { active: item.id == _vm.activeItemID },
                            on: {
                              click: function ($event) {
                                return _vm.setActiveItem(item)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("span", { staticClass: "text-muted" }, [
                      _vm._v(" Content "),
                    ]),
                  ]),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-alert",
                            {
                              attrs: {
                                variant: "info",
                                show: _vm.replyActiveItemCategory == "default",
                              },
                            },
                            [
                              _vm._v(
                                " You are editing a default reply. To personalize, select a different category on the dropdown to the right. Using {patient} and {doctor} will automatically insert the patient's name and your name for you when you add it in messages. "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "label",
                            { attrs: { for: "replyActiveItemName" } },
                            [_vm._v("Name")]
                          ),
                          _c("b-form-input", {
                            attrs: {
                              id: "replyActiveItemName",
                              placeholder: "Enter title here...",
                              readonly: Boolean(_vm.currentDoctor.id),
                            },
                            model: {
                              value: _vm.activeItemName,
                              callback: function ($$v) {
                                _vm.activeItemName = $$v
                              },
                              expression: "activeItemName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "label",
                            { attrs: { for: "replyActiveItemCategory" } },
                            [_vm._v("Category")]
                          ),
                          _c("b-form-select", {
                            attrs: {
                              options: _vm.categoryOptions,
                              disabled: Boolean(_vm.currentDoctor.id),
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "first",
                                fn: function () {
                                  return [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: "" } },
                                      [_vm._v(" No Category ")]
                                    ),
                                    _c(
                                      "b-form-select-option",
                                      {
                                        attrs: {
                                          value: "default",
                                          disabled: "",
                                        },
                                      },
                                      [_vm._v("Default")]
                                    ),
                                    _c(
                                      "b-form-select-option",
                                      {
                                        staticClass: "text-primary",
                                        attrs: { value: "add" },
                                      },
                                      [_vm._v("+ Add New Category")]
                                    ),
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { disabled: "" } },
                                      [_vm._v(" ---- ")]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                            model: {
                              value: _vm.replyActiveItemCategory,
                              callback: function ($$v) {
                                _vm.replyActiveItemCategory = $$v
                              },
                              expression: "replyActiveItemCategory",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.showAddCategoryField
                    ? _c(
                        "b-row",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "b-col",
                            { attrs: { offset: "6", cols: "6" } },
                            [
                              _c(
                                "label",
                                { attrs: { for: "addCategoryName" } },
                                [_vm._v("New Category")]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "addCategoryName",
                                  placeholder:
                                    "Enter new category name here...",
                                },
                                model: {
                                  value: _vm.newCategoryName,
                                  callback: function ($$v) {
                                    _vm.newCategoryName = $$v
                                  },
                                  expression: "newCategoryName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "mt-4 mb-0",
                          attrs: { for: "replyActiveItemContent" },
                        },
                        [_vm._v("Content")]
                      ),
                      _c(
                        "b-dropdown",
                        {
                          attrs: {
                            text: "Insert",
                            variant: "link",
                            dropleft: "",
                            "no-caret": "",
                            "toggle-class": "merge-dropdown-button",
                            "menu-class": "merge-dropdown",
                          },
                        },
                        _vm._l(_vm.mergeTags, function (tag) {
                          return _c(
                            "b-dropdown-item",
                            {
                              key: tag.tag,
                              on: {
                                click: function ($event) {
                                  return _vm.insertTagAtCursor(tag.tag)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(tag.tag) + " ")]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("b-form-textarea", {
                    ref: "ContentTextArea",
                    attrs: {
                      id: "replyActiveItemContent",
                      placeholder: "Enter message content here...",
                      rows: "8",
                      "no-resize": "",
                      readonly: Boolean(_vm.currentDoctor.id),
                    },
                    model: {
                      value: _vm.activeItemContent,
                      callback: function ($$v) {
                        _vm.activeItemContent = $$v
                      },
                      expression: "activeItemContent",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.currentDoctor.id
            ? _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "3" } }),
                  _c(
                    "b-col",
                    { attrs: { cols: "9" } },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "link" },
                          on: { click: _vm.openUpload },
                        },
                        [
                          _c("icon-paperclip", {
                            staticClass: "tw-w-5 tw-h-5 tw-text-gray-800",
                          }),
                        ],
                        1
                      ),
                      _c("input", {
                        ref: "fileInput",
                        staticStyle: { display: "none" },
                        attrs: { type: "file", multiple: "" },
                        on: { change: _vm.uploadFile },
                      }),
                      _vm.files.length > 0
                        ? _c("p", { staticClass: "text-muted" }, [
                            _vm._v("Attachments"),
                          ])
                        : _vm._e(),
                      _c(
                        "ul",
                        _vm._l(_vm.files, function (file, index) {
                          return _c(
                            "li",
                            { key: `${index} - ${file.name}` },
                            [
                              file.name
                                ? _c("span", [_vm._v(_vm._s(file.name))])
                                : _c("span", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: file.previewURL,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(file.filename))]
                                    ),
                                  ]),
                              file.name
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteAttachment(index)
                                        },
                                      },
                                    },
                                    [_c("b-icon-x")],
                                    1
                                  )
                                : _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "link" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteAttachment(
                                            index,
                                            file.id
                                          )
                                        },
                                      },
                                    },
                                    [_c("b-icon-x")],
                                    1
                                  ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          !_vm.currentDoctor.id
            ? _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c("b-col", { attrs: { cols: "3" } }),
                  _c("b-col", { attrs: { cols: "9" } }, [
                    _c(
                      "div",
                      { staticClass: "float-right mt-4" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "px-5",
                            attrs: {
                              variant: "outline-primary",
                              size: "lg",
                              pill: "",
                              disabled: _vm.disableDelete,
                            },
                            on: { click: _vm.showModal },
                          },
                          [_vm._v(" Delete ")]
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "px-5 ml-4",
                            attrs: {
                              variant: "primary",
                              size: "lg",
                              pill: "",
                              disabled: _vm.disableSave,
                            },
                            on: { click: _vm.saveReply },
                          },
                          [_vm._v(" Save ")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: {
            "hide-header": "",
            centered: "",
            "hide-footer": "",
            size: "s",
          },
          model: {
            value: _vm.showDeleteModal,
            callback: function ($$v) {
              _vm.showDeleteModal = $$v
            },
            expression: "showDeleteModal",
          },
        },
        [
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-h": "center" } },
            [
              _c("b-col", { attrs: { cols: "2" } }),
              _c("b-col", { attrs: { cols: "10" } }, [
                _c("h1", { staticClass: "heading p-4 mb-0" }, [
                  _vm._v(" Are you sure you want to delete this template? "),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "p-4", attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        pill: "",
                        block: "",
                        variant: "outline-primary",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.deleteActiveItem()
                        },
                      },
                    },
                    [_vm._v("Confirm")]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { pill: "", block: "", variant: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.showDeleteModal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-toast",
        {
          attrs: {
            solid: "",
            toaster: "b-toaster-bottom-center",
            id: "replyToast",
            variant: _vm.toast.variant,
          },
        },
        [_vm._v(" " + _vm._s(_vm.toast.message) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }